import React, { useState } from 'react'
import images from '../../resources/images';
import './BlogStyles.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Brands from '../home/Brands';

const BlogContainer = () => {

    return (
        <React.Fragment>
            <Helmet>
                <title>Top Software Development Services In Bangalore | Geekonomy</title>
                <meta name="description" content="Geekonomy's superior services in Bangalore will help you increase customer retention. Excellence in digital marketing for customer connections." />
                <meta
                    name="keywords"
                    content="web developing agency in bangalore,web designers in bangalore,website designers in bangalore , how to design a website,how to create a website,content marketing, email marketing"
                />
            </Helmet>
            <div class="row-images">
                <div class="column-images">
                    <Link to="/firstBlog">
                        <div className="image-container">
                            <img src={images.Brand_storytelling} style={{ width: "100%", height: "40vh" }} />
                            <div className="text-headings">
                                <div className="imageHeading" >
                                    <p className='imageText'>The Power of Brand Storytelling: </p>
                                </div>
                                <div className="imageSubHeading firstCardImg" style={{ paddingBottom: "25px" }}>
                                    <p className='imageSubText'>How Geekonomy Helps Brands Connect with Customers</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to="/secondBlog">
                        <div className="image-container">
                            <img src={images.Ux_design} style={{ width: "100%", height: "40vh" }} />
                            <div className="imageHeading">
                                <p className='imageText thirdCardText'>UX Design Trends for 2024:
                                </p>
                            </div>
                            <div className="imageSubHeading thirdCardImg">
                                <p className='imageSubText'> Geekonomy's Guide to Creating User-Centric Experiences</p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/thirdBlog">
                        <div className="image-container">
                            <img src={images.Responsive_webdesign} style={{ width: "100%", height: "40vh" }} />
                            <div className="imageHeading">
                                <p className='imageText'>Why Responsive Web Design is Essential:
                                </p>
                            </div>
                            <div className="imageSubHeading secondCardImg">
                                <p className='imageSubText'>Geekonomy's Approach to Mobile Optimizations</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="column-images">
                    <Link to="/fourthBlog">
                        <div className="image-container">
                            <img src={images.E_commerce} style={{ width: "100%", height: "40vh" }} />
                            <div className="imageHeadingTypography">
                                <p className='imageText'>The Future of E-Commerce: </p>
                            </div>
                            <div className="imageSubHeading forthCardImg">
                                <p className='imageSubText' style={{ paddingBottom: "5px" }}>Geekonomy's Strategies for Online Success</p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/fifthBlog">
                        <div className="image-container">
                            <img src={images.Content_marketing} style={{ width: "100%", height: "40vh" }} />
                            <div className="imageHeading mb-2">
                                <p className='imageText' >Content Marketing in 2024:
                                </p>
                            </div>
                            <div className="imageSubHeading fiveCardImg">
                                <p className='imageSubText' style={{ paddingBottom: "13px" }}>
                                    Geekonomy's Tips for Creating Engaging Content</p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/sixthblog">
                        <div className="image-container">
                            <img src={images.Social_media_marketing} style={{ width: "100%", height: "40vh" }} />
                            <div className="imageHeading">
                                <p className='imageText'>Social Media Marketing Trends to Watch: </p>
                            </div>
                            <div className="imageSubHeading firstCardImg">
                                <p className='imageSubText'>Geekonomy shares insights on the latest social media marketing trends to watch, offering strategies for crafting </p>
                            </div>
                        </div>
                    </Link>



                    <Link to="/seventhblog">
                        <div className="image-container">
                            <img src={images.Importance_of_seo} style={{ width: "100%", height: "40vh" }} />
                            <div className="imageHeading">
                                <p className='imageText SEO'>The Importance of SEO in Today's Digital Landscape:</p>

                            </div>
                            <div className="imageSubHeading secondCardImg">
                                <p className='imageSubText'>
                                    Geekonomy's Best Practices</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="column-images">
                    <Link to="/eighthblog">
                        <div className="image-container">
                            <img src={images.Stunning_logos} style={{ width: "100%", height: "40vh" }} />
                            <div className="imageHeading">
                                <p className='imageText thirdCardText'>How Geekonomy Designs Stunning Logos that Stand Out in a Crowded Market</p>

                            </div>
                            <div className="imageSubHeading thirdCardImg">
                                <p className='imageSubText'>
                                    Geekonomy's Distinctive Logo Design</p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/ninethblog">
                        <div className="image-container">
                            <img src={images.Digital_advertising} style={{ width: "100%", height: "40vh" }} />
                            <div className="imageHeadingTypography">
                                <p className='imageText'>Digital Advertising Strategies:</p>
                            </div>
                            <div className="imageSubHeading forthCardImg">
                                <p className='imageSubText'>
                                    Geekonomy's Approach to Maximizing ROI</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/tenthblog">
                        <div className="image-container">
                            <img src={images.Ai_marketing} style={{ width: "100%", height: "40vh" }} />
                            <div className="imageHeading mb-2">
                                <p className='imageText'>The Role of AI in Marketing:
                                </p>
                            </div>
                            <div className="imageSubHeading fiveCardImg">
                                <p className='imageSubText' style={{ paddingBottom: "15px" }}>Geekonomy's Guide to Leveraging Technology for Success</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className='twoContentScetion'>
                <Brands />
            </div>
        </React.Fragment >
    )
}

export default BlogContainer;
