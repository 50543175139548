import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import images from '../../resources/images'
import Brands from '../home/Brands'
import { Link } from 'react-router-dom';


const SixthBlogPage = () => {
    const [click, setClick] = useState(false);
    const handleNavLinkClick = () => {
        if (click) {
            setClick(false);
        }
        window.scrollTo(0, 0);
    };
    return (
        <React.Fragment>
            <Container className='mt-5 rightContent'>
                <div className='mb-5'>
                    <img src={images.Social_media_marketing} alt='Social Media Marketing' className='sixthblog' />
                </div>

                <h1 className='blogs-heading'>Social Media Marketing Trends to Watch: Geekonomy's Insights for Effective Campaigns</h1>
                <div className='missionText'>
                    <div >
                        <p>Social media marketing is a constantly evolving field, with new trends and technologies shaping the
                            way that brands engage with their audience. In 2024, several trends are shaping the world of social
                            media marketing, and staying ahead of these trends is essential for creating effective campaigns
                            that drive engagement and conversions.
                        </p>
                    </div>
                    <div>
                        <p>
                            One of the key trends in social media marketing is the rise
                            of ephemeral content. Ephemeral content, such as Instagram Stories and Snapchat Snaps, is
                            content that is only available for a short period of time before disappearing. This type of content can
                            create a sense of urgency and encourage users to engage with your brand in real-time.
                        </p>
                    </div>
                    <div>
                        <p>
                            Another
                            trend in social media marketing is the use of social commerce. Social media platforms are
                            increasingly becoming shopping destinations in their own right, with features that allow users to
                            discover and purchase products without leaving the platform. Brands that can leverage social
                            commerce effectively are well-positioned to capitalize on this trend and drive sales through social
                            media.
                        </p>
                    </div>
                    <div>
                        <p>
                            Additionally, the rise of social media influencers is changing the way that brands reach their
                            audience. Influencers have the ability to reach a large and engaged audience and can help to
                            amplify your brand's message.


                        </p>
                    </div>
                    <div>
                        <p> By partnering with influencers who align with your brand values, you
                            can reach new audiences and build credibility with your existing audience. In conclusion, social
                            media marketing continues to be a powerful tool for brands looking to engage with their audience
                            and drive sales.
                        </p>
                    </div>
                    <div>
                        <p>
                            At Geekonomy, we help our clients navigate the ever-changing world of social
                            media marketing and develop campaigns that deliver real results. From influencer partnerships to
                            ephemeral content to social commerce, we work closely with our clients to create campaigns that
                            resonate with their audience and drive engagement.
                        </p>
                    </div>

                </div>

            </Container>
            <div className='mt-5 mb-5 emptySpace1'></div>
            <Container>
                <div className='mt-4'>
                    <div style={{ position: 'relative' }} className='mt-4 mb-4'>
                        {/* <img src={images.arrow_circle} alt="Circle" className='blogImgCircle' />
                        <img src={images.right_arrow} alt="Arrow" className='blogImgArrow' /> */}
                        <Link to="/seventhblog" onClick={handleNavLinkClick}>
                            <Button className='nextBtn'>Next</Button>
                        </Link>
                    </div>
                </div>
            </Container>
            <div className='mt-5 mb-5 emptySpace'></div>
            <div className='twoContentScetion'>
                <Brands />
            </div>
        </React.Fragment>
    )
}

export default SixthBlogPage;
