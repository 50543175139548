import React from 'react'
import CustomDevelopment from './customDevelopment/CustomDevelopment';

const ServiceContainer = () => {
    return (
        <div>
            <CustomDevelopment />
        </div>
    )
}

export default ServiceContainer;
