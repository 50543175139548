import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import './HomeStyle.css';
import { Container } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

const Brands = () => {

    const [click, setClick] = useState(false);
    const handleNavLinkClick = () => {
        if (click) {
            setClick(false);
        }
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (
        <React.Fragment>
            <div className='flexContainer d-flex flex-column align-items-center'>
                <div className="text-wrapper">
                    <p className='getInText text-center'>
                        <span className={isHovered ? "white-text" : "red-text"}>Shaping Brands,</span>
                        <span
                            className={isHovered ? "red-text ms-2" : "white-text ms-2"}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            Building Solutions.
                        </span>
                    </p>
                </div>
            </div>
            <div className='contactBtnSection'>
                <Container className='d-flex flex-column align-items-center'>

                    <Button className='contactBtn' variant="light">
                        <NavLink
                            className='getintouch'
                            to="/ContactUs"
                            onClick={() => { handleNavLinkClick(); }}
                            style={{ textDecoration: 'none', color: 'black' }} // Custom styles
                        >
                            Get In Touch
                        </NavLink>
                    </Button>
                </Container>
            </div>


        </React.Fragment>
    )
}

export default Brands;
