import React, { useState } from 'react';
import './CyberSecurity.css';
import images from "../../../resources/images";
import { Container, Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';
import CyberSecurityAbout from "./accordian";
function CyberSecurity() {
    const [click, setClick] = useState(false);
    const handleNavLinkClick = () => {
        if (click) {
            setClick(false);
        }
        window.scrollTo(0, 0);
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>Best custom development services in Bangalore | Geekonomy</title>
                <meta name="description" content="Unleash Your Ideas with Custom Development. Web, mobile apps, software solutions - We bring your vision to life with expertise and innovation." />
                <meta
                    name="keywords"
                    content="best branding services, webdevelopment agencies, best customer retention services, geekonomy,best branding agency in bangalore,seo, sem best website optimisation services, web developers"
                />
            </Helmet>
            <div className="main-image-container" >
                <img
                    src={images.CyberSecurity}
                    alt="branding bg"
                    className="main-image-style"
                />
                <div className="marquee-container">
                    <div className="marquee">
                        <span>Empowering Security, Catalyzing Resilience.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>
                    <div className="marquee marquee2">
                        <span>Empowering Security, Catalyzing Resilience.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div>
                    <p className='missionText '> Fortify your digital stronghold, stay ahead of threats with Geekonomy's vigilant protection.</p >
                    <p className='missionText '>Safeguard your digital fortress with Geekonomy's state-of-the-art cybersecurity solutions. In an era where threats lurk in the shadows of cyberspace, we stand as sentinels of protection, deploying advanced algorithms and proactive monitoring to shield your assets from harm. By anticipating and neutralising threats before they strike, we ensure that your business remains resilient in the face of evolving dangers, forging a path toward a secure future in the digital frontier.</p>
                </div>
                <hr />
                <div className='numberContainer'>
                    <h2 className='d-flex  justify-content-center'>What We Do</h2>
                </div>
                <div className='mt-5'>
                    < CyberSecurityAbout />
                </div>
            </div>
        
            <div className="d-flex flex-column align-items-center  brandingSolutionFooter ">
                <Container>
                    <h3 className='getinText text-center text-md-start largeScreen d-none d-md-block'>
                        Defend your digital assets with our robust cybersecurity solutions. We prioritize your safety, shielding your data from emerging cyber threats.
                    </h3>
                </Container>
                <p className="d-md-none serviceFooter rightContent getinText">
                    We specialize in creating cohesive brand identities that resonate with your audience. From
                    logo design to brand strategy, we work closely with you to bring your vision to life.
                </p>
                <div className="mt-5">
                    <Button className='aboutUsBtn' variant="light">
                        <NavLink
                            to="/ContactUs"
                            onClick={() => { handleNavLinkClick(); }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            Get In Touch
                        </NavLink>
                    </Button>
                </div>
            </div>
        </React.Fragment >
    )
}
export default CyberSecurity;