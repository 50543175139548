import React, { useRef, useState } from 'react';
import { Button } from "react-bootstrap";
import "./DigitalMarketing.css";
import images from "../../../resources/images";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import videsss from "../../../assets/videos/Digital marketing V4.mp4";
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const DigitalMarketing = () => {
  const [click, setClick] = useState(false);
  const handleNavLinkClick = () => {
    if (click) {
      setClick(false);
    }
    window.scrollTo(0, 0);
  };

  const videoRef = useRef(null);

  const handleVideoEnded = () => {
    videoRef.current.play();
  };

  return (
    <div>
      <Helmet>
        <title>Best Digital marketing company in Bangalore |geekonomy</title>
        <meta name="description" content="Use Geekonomy's experience in digital marketing to strengthen your brand. Boost your internet visibility with targeted tactics and quantifiable outcomes." />
        <meta name="keywords" content="Digital marketing,Online marketing,Internet marketing,SEO,PPC ,Social media marketing,Content marketing,Email marketing,Digital
         advertising,Website optimization,Conversion rate optimization,Marketing analytics,Online branding,Digital strategy,Lead generation" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="main-image-container" >
        <img
          src={images.Digital_bg}
          alt="branding bg"
          className="main-image-style"
        />
        <div className="marquee-container">
          <div className="marquee">
            <span>Empowering Brands, Fueling Growth.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div className="marquee marquee2">
            <span>Empowering Brands, Fueling Growth.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
        </div>

        <div className="digital-frame" >
          <img
            alt="Lightbulb"
            className=" digital-frame-style"
            src={images.Digital_frame}
          />
        </div>
        <div className="digital-man" >
          <img
            alt="Lightbulb"
            className=" digital-man-style"
            src={images.Digital_man}
          />
        </div>
      </div>

      <div>
        <div className="container py-5">
          <div >
            <p className="missionText">
              Revolutionise your outreach, shape the future of engagement with Geekonomy's AI-driven strategies.
            </p>
            <p className="missionText">
              Step into the future of marketing with Geekonomy's AI-powered digital strategies. Our revolutionary approach merges human creativity with artificial intelligence to sculpt campaigns that resonate with your audience on a profound level. Harnessing the predictive prowess of AI, we sculpt personalised content experiences and analyse data in real-time to optimise every aspect of your online presence. Join forces with us to unlock unparalleled results, maximising your ROI and propelling your brand into the stratosphere of success.
            </p>
          </div>
          <hr />
        </div>
        <div>
          <div className="video-containers">
            <video ref={videoRef} autoPlay loop muted onEnded={handleVideoEnded}>
              <source src={videsss} type="video/mp4" />
            </video>
          </div>
        </div>
       
        <div className="d-flex flex-column align-items-center  brandingSolutionFooter ">
          <Container>
            <h3 className='getinText text-center text-md-start largeScreen d-none d-md-block'>
              With our specialised digital marketing solutions, you can maximise the potential of your online presence.
              We are here to help you advance your company in the digital sphere, from compelling campaigns to strategic branding.
            </h3>
          </Container>
          <p className="d-md-none serviceFooter rightContent">
            With our specialised digital marketing solutions, you can maximise the potential of your online presence. We are here to help you advance your company in the digital sphere, from compelling campaigns to strategic branding.
          </p>
          <div className="mt-5">
            <Button className='aboutUsBtn' variant="light">
              <NavLink
                to="/ContactUs"
                onClick={() => { handleNavLinkClick(); }}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                Get In Touch
              </NavLink>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DigitalMarketing;