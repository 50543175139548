import React from "react";
import { Row } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import img1 from "../../assets/terrific/1.png";
import img2 from "../../assets/terrific/2.png";
import img3 from "../../assets/terrific/3.png";
import img4 from "../../assets/terrific/4.png";
import img5 from "../../assets/terrific/5.png";
import img6 from "../../assets/terrific/6.png";
import img7 from "../../assets/terrific/7.png";
import img8 from "../../assets/terrific/8.png";
import img9 from "../../assets/terrific/9.png";
import img10 from "../../assets/terrific/10.png";
import img11 from "../../assets/terrific/11.png";
import mobileImage from "../../assets/terrific/12.png";
import "./Terrific.css";


const clientSectiOne = [
    {
        id: 'client1',
        img: img1,
    },
    {
        id: 'client2',
        img: img2,
    },
    {
        id: 'client3',
        img: img3,
    },
    {
        id: 'client4',
        img: img4,
    },
    {
        id: 'client5',
        img: img5,
    },
    {
        id: 'client6',
        img: img6,
    },
    {
        id: 'client7',
        img: img7,
    },
    {
        id: 'client8',
        img: img8,
    },
    {
        id: 'client9',
        img: img9,
    },
    {
        id: 'client10',
        img: img10,
    },
    {
        id: 'client11',
        img: img11,
    },
];

const Terrific = () => {
    return (
        <div>

            <div className="container">
                <div className="text-center">
                    <Row className="mt-0">
                        <h1 className="social-commerce">5x Social Commerce ROI</h1>
                    </Row>
                    <Row className="text-center">
                        <p className="font-weight-250 leveraging-text" >
                            Leveraging actionable intelligence
                        </p>
                    </Row>
                </div>
            </div>

            <div style={{ position: "relative" }}>
                <Marquee speed={50}>
                    {clientSectiOne.map(client => (
                        <img
                            key={client.id}
                            src={client.img}
                            alt={client.id}
                            style={{ width: "350px", height: "auto", position: "relative" }}
                        />
                    ))}
                </Marquee>
                <Marquee direction="right" speed={50} style={{ position: "relative" }}>
                    {clientSectiOne.map(client => (
                        <img
                            key={client.id}
                            src={client.img}
                            alt={client.id}
                            style={{ width: "350px", height: "auto", position: "relative" }}
                        />
                    ))}
                </Marquee>
                <div className="mobile-image-container" style={{ position: "absolute", top: "5%", left: "50%", transform: "translateX(-50%)", zIndex: "9999" }}>
                    <img src={mobileImage} alt="Mobile" className="mobile-image"/>
                </div>
            </div>

        </div>
    );
};

export default Terrific;
