import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom"
import AboutUs from "./AboutUs";
import BrandsSlider from "./BrandsSlider";
import { Col, Container, Row } from "react-bootstrap";
import images from "../../resources/images";
import ImageSlider2 from "./ImageSlider2";
import { Helmet } from "react-helmet";
import Brands from "./Brands";
import bg from "../../resources/bg";
import Terrific from "./Terrific";

const HomeContainer = () => {

  const [click, setClick] = useState(false);
  const handleNavLinkClick = () => {
    if (click) {
      setClick(false);
    }
    window.scrollTo(0, 0);
  };
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const history = useNavigate();

  const imageLinks = [
    "/branding",
    "/custom",
    "/cyber",
    "/digital",
    "/customer"
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % Object.keys(bg).length);

    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const handleImageClick = () => {
    const link = imageLinks[currentImageIndex];
    history(link);
  }




  return (
    <React.Fragment>
      <Helmet>
        <title>Geekonomy Technology Private Ltd, bangalore</title>
        <meta
          name="description"
          content="The best online growth partner for you is geekonomy. Services include digital marketing, branding, customer retention, software development, and cybersecurity."
        />
        <meta
          name="keywords"
          content="branding,cybersecurity,digital marketing,customer retention, software development, geekonomy"
        />
      </Helmet>

      <div className=" slider-container mt-5">
        <div className="slider-home">
          {Object.keys(bg).map((key, index) => (
            <img
              key={key}
              src={`${bg[key]}`}
              className={`slide-home ${index === currentImageIndex ? "active_home" : ""}`}
              alt={`bg${index + 1}`}
              onClick={handleImageClick}
            />
          ))}
          <p className="our-universe">Navigating to the Digital <br /> Universe</p>
        </div>
        <div className="gradient-image"></div>
      </div>
      <div className="mt-5">
        <AboutUs />
      </div>
      <div className="brandSliderSection">
        <BrandsSlider />
      </div>

      <Container className="expertiseSection">
        <div className="d-flex justify-content-center ">
          <h1 className="expertiseContainer">Our Expertise</h1>
        </div>
        <div className="mt-4 mb-3">
          <h4 className="expertiseSubTitle rightContent text-center">
            Our expertise in international and local markets pivot us as a
            powerful mixture of strategists and pragmatists with an extensive network all across
            India, UAE, London, and USA.
          </h4>
        </div>


        <div className="mt-5 mb-5">
          <Row className="justify-content-center no-gutters"> {/* Add the 'no-gutters' class here */}
            <Col xs={6} sm={4} md={3} lg={2}>
              <Link to="/branding">
                <div className="img-overlay">
                  <img src={images.Geek_Services_01} alt="Image 1" className="img-fluid gallery-img" />
                  <div className="overlay-text">Branding</div>
                </div>
              </Link>
            </Col>
            <Col xs={6} sm={4} md={3} lg={2}>
              <Link to="/custom">
                <div className="img-overlay">
                  <img src={images.Geek_Services_02} alt="Image 2" className="img-fluid gallery-img" />
                  <div className="overlay-text">Custom Development</div>
                </div>
              </Link>
            </Col>
            <Col xs={6} sm={4} md={3} lg={2}>
              <Link to="/cyber">
                <div className="img-overlay">
                  <img src={images.Geek_Services_03} alt="Image 3" className="img-fluid gallery-img" />
                  <div className="overlay-text">Cyber Security</div>
                </div>
              </Link>
            </Col>
            <Col xs={6} sm={4} md={3} lg={2}>
              <Link to="/digital">
                <div className="img-overlay">
                  <img src={images.Geek_Services_04} alt="Image 4" className="img-fluid gallery-img" />
                  <div className="overlay-text">Digital Marketing</div>
                </div>
              </Link>
            </Col>
            <Col xs={6} sm={4} md={3} lg={2}>
              <Link to="/customer">
                <div className="img-overlay">
                  <img src={images.Geek_Services_05} alt="Image 5" className="img-fluid gallery-img" />
                  <div className="overlay-text">Customer Retension</div>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
      <div className="mb-5 mt-5">
        <Terrific />
      </div>
      <Container className=" expertiseSection">
        <div className="d-flex justify-content-center chroniclesSection">
          <p>Inspiration Station</p>
        </div>
        <div className="mb-5 mt-1 imagesliderSection">
          <ImageSlider2 />
        </div>
      </Container>
      <div className="twoContentScetion">
        <Brands />
      </div>
    </React.Fragment>
  );
};

export default HomeContainer;
