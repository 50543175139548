import logo from "../../assets/images/logo.svg";
import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from "react-router-dom";
import './NavbarStyle.css';
import images from '../../resources/images';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
const NavbarContainer = () => {
    const [click, setClick] = useState(false);
    const [, setScrollPosition] = useState(0);
    const location = useLocation();
    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleMouseEnter = () => {
        setDropdownOpen(true);
    };
    const handleMouseLeave = () => {
        setDropdownOpen(false);
    };
    const handleCloseDropdown = () => {
        setDropdownOpen(false);
    };
    const handleClick = () => setClick(!click);
    const handleNavLinkClick = () => {
        if (click) {
            setClick(false);
        }
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };
    return (
        <nav className="navbar">
            <div className="nav-container">
                <NavLink exact to="/" className="nav-logo" onClick={() => {
                    handleClick();
                    handleNavLinkClick();
                }}>
                    <span className="icon">
                        <img className='nav_logo' src={logo} alt='logo' />
                    </span>
                </NavLink>
                <ul className={click ? "nav-menu active  mt-3" : "nav-menu mt-3"}>
                    <li className="nav-item">
                        <NavLink
                            exact
                            to="/"
                            activeClassName="active"
                            className="nav-links"
                            onClick={() => {
                                handleClick();
                                handleNavLinkClick();
                            }}
                        >
                            Home
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            exact
                            to="/aboutUs"
                            activeClassName="active"
                            className="nav-links"
                            onClick={() => {
                                handleClick();
                                handleNavLinkClick();
                            }}
                        >
                            About Us
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <Dropdown
                            show={dropdownOpen}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <NavLink
                                    activeClassName="active"
                                    className={`nav-links expertiseNav ${location.pathname === '/branding' || location.pathname === '/digital' || location.pathname === '/custom' ? 'whiteText' : 'redText'}`}
                                >
                                    Expertise
                                </NavLink>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='expertise'>
                                <NavLink to="/branding" className="dropdown-item" onClick={() => {
                                    handleClick();
                                    handleNavLinkClick();
                                }}>
                                    Branding Solutions
                                </NavLink>
                                <NavLink to="/custom" className="dropdown-item" onClick={() => {
                                    handleClick();
                                    handleNavLinkClick();
                                }}>
                                    Custom Development
                                </NavLink>
                                <NavLink to="/cyber" className="dropdown-item" onClick={() => {
                                    handleClick();
                                    handleNavLinkClick();
                                }}>
                                    Cyber Security
                                </NavLink>
                                <NavLink to="/digital" className="dropdown-item" onClick={() => {
                                    handleClick();
                                    handleNavLinkClick();
                                }}>
                                    Digital Marketing
                                </NavLink>
                                <NavLink to="/customer" className="dropdown-item" onClick={() => {
                                    handleClick();
                                    handleNavLinkClick();
                                }}>
                                    Customer Retention
                                </NavLink>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            exact
                            to="/blog"
                            activeClassName="active"
                            className="nav-links"
                            onClick={() => {
                                handleClick();
                                handleNavLinkClick();
                            }}
                        >
                            Blog
                        </NavLink>
                    </li> 
                    <li className="nav-item  contact-mobile">
                        <NavLink
                            exact
                            to="/ContactUs"
                            variant="outline-light"
                            activeClassName="active"
                            className="nav-links "
                            onClick={() => {
                                handleClick();
                                handleNavLinkClick();
                            }}
                        >
                            Contact Us
                        </NavLink>
                    </li>
                </ul>
                <div className='naContactBtn '>
                    <Button className='navBtn' variant="outline-light">  <NavLink
                        exact
                        to="/ContactUs"
                        variant="outline-light"
                        activeClassName="active"
                        className="nav-links "
                        onClick={() => {
                            handleClick();
                            handleNavLinkClick();
                        }}
                    >
                        Contact Us
                    </NavLink></Button>
                </div>
                <div className="nav-icon nav-icon-mobile" onClick={handleClick}>
                    <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
                    {click ? (
                        <span className="icon">
                            <img src={images.close_icon} alt='close' />
                        </span>
                    ) : (
                        <span className="icon">
                            <img src={images.menu_icon} alt='menu' />
                        </span>
                    )}
                </div>
            </div>
        </nav>
    )
}
export default NavbarContainer;