import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import images from '../../resources/images';
import './FooterStyle.css';
import logo from '../../assets/images/logo.svg'
import { NavLink } from "react-router-dom";
const FooterContainer = () => {
    const [click, setClick] = useState(false);
    const handleNavLinkClick = () => {
        if (click) {
            setClick(false);
        }
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };
    return (
        <React.Fragment>
            <Container className='footerSection'>
                <hr />
                <Row className='d-flex justify-content-center'>
                    <Col className="mobile-view">
                        <img src={logo} alt="footerlogo" />
                        <div className='mt-4 rightContent'>
                            <span className='footerFirst'>Geekonomy is a team of driven and innovative individuals, blending passion with curiosity to deliver exceptional value through design.
                            </span>
                            <div className='mt-3'>
                                <span className='footerFirst '>
                                    In today's world, we embrace marketing as an integral part of life, crafting captivating experiences that seamlessly bridge the online and offline realms.
                                    Our expertise lies in empowering brands to share their unique narratives, fueling transformative growth.</span>
                            </div>
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-center ">
                        <div className='ms-5 mt-3 mobiles-view'>
                            <span className='footerHeading mobiles-view-2'>Services</span>
                            <div className='mt-3 d-flex flex-column justify-content-between mobiles-view mobiles-view-space'>
                                <span className='mt-3 mobiles-view '>
                                    <NavLink to="/branding" className="dropdown-item serviceScetion" onClick={() => {
                                        handleNavLinkClick();
                                    }} >
                                        Branding Solutions
                                    </NavLink>
                                </span>
                                <span className='mt-3 mobiles-view'>
                                    <NavLink to="/custom" className="dropdown-item serviceScetion" onClick={() => {
                                        handleNavLinkClick();
                                    }}>
                                        Custom Development
                                    </NavLink>
                                </span>
                                <span className='mt-3 mobiles-view'>
                                    <NavLink to="/cyber" className="dropdown-item serviceScetion" onClick={() => {
                                        handleNavLinkClick();
                                    }}>
                                        Cyber Security
                                    </NavLink>
                                </span>
                                <span className='mt-3 mobiles-view'>
                                    <NavLink to="/digital" className="dropdown-item serviceScetion" onClick={() => {
                                        handleNavLinkClick();
                                    }}>
                                        Digital Marketing
                                    </NavLink>
                                </span>
                                <span className='mt-3 mobiles-view'>
                                    <NavLink to="/customer" className="dropdown-item serviceScetion" onClick={() => {
                                        handleNavLinkClick();
                                    }}>
                                        Customer Retention
                                    </NavLink>
                                </span>
                            </div>
                            {/* <div className='mt-3 d-flex flex-column justify-content-between'>
                                <span className='mt-3 footerHeading'>Explore</span>
                                <span className='mt-3 '>
                                    <NavLink to="/blog" className="dropdown-item serviceScetion" onClick={() => {
                                        handleNavLinkClick();
                                    }}>
                                        Blog
                                    </NavLink></span>
                            </div> */}
                            <div className='mt-3 d-flex flex-column justify-content-between mobiles-view mobiles-view-space'>
                                <span className='mt-3 footerHeading mobiles-view'>Partners</span>
                                <div className=' d-flex justify-content-left mt-2'>
                                    <img src={images.leadspotting} alt='leadspotting' className='social-icon mobiles-view' />
                                    <img src={images.teraffic} alt='teraffic' className='social-icon mobiles-view' />
                                    <img src={images.google} alt='google' className='social-icon mobiles-view' />
                                    <img src={images.amazon} alt='amazon' className='social-icon mobiles-view' />
                                    <img src={images.adobe} alt='adobe' className='social-icon mobiles-view' />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <div className='mt-3 mobiles-view-1'>
                            <span className='footerHeading'>Company</span>
                            <div className='mt-3 d-flex flex-column justify-content-between'>
                                <span className='mt-2 serviceScetion'> <NavLink to="/aboutUs" className="dropdown-item " onClick={() => {
                                    handleNavLinkClick();
                                }}>About us</NavLink></span>
                                <span className='mt-2 serviceScetion'><NavLink to="/ContactUs" className="dropdown-item " onClick={() => {
                                    handleNavLinkClick();
                                }}>Contact us</NavLink></span>
                                <span className='mt-2 serviceScetion'><NavLink to="/PrivacyPolicy" className="dropdown-item " onClick={() => {
                                    handleNavLinkClick();
                                }}>Privacy Policy</NavLink></span>
                                <span className='mt-2 serviceScetion'><NavLink to="/termsAndConditions" className="dropdown-item " onClick={() => {
                                    handleNavLinkClick();
                                }}>Term & conditions</NavLink></span>
                                <span className='mt-3 serviceScetion'></span>
                            </div>
                            <div className='mt-3 d-flex flex-column justify-content-between'>
                                <span className=' mt-3 footerHeading'>Contact</span>
                                <span className='mt-3 serviceScetion'>+91- 076248 18717</span>
                                <span className='mt-3 serviceScetion'>info@geekonomy.in</span>
                            </div>
                        </div>
                    </Col>
                    <Col >
                        <div className='mt-3 mobileview-address'>
                            <span className='footerHeading'>Address</span>
                            <div className='mt-2'>
                                <span className='serviceScetion'>24, 1st cross road, Magrath Rd, Bengaluru, Karnataka 560025</span>
                                <iframe
                                    title="Address Map"
                                    width="300"
                                    height="250"
                                    style={{ border: 0 }}
                                    src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.023478408096!2d77.60619527575561!3d12.970349414904225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1681f9dec0e9%3A0xdaec705c2f2ff0ed!2sGeekonomy%20Technology%20Private%20Limited.!5e0!3m2!1sen!2sin!4v1712815681173!5m2!1sen!2sin`}
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr />
                <div className='d-flex justify-content-between'>
                    <span className='serviceScetion serviceScetion1'>© 2024 Geekonomy. All rights reserved</span>
                    <div>
                        <img src={images.youtube} alt='youtube' className='social-icon' />
                        <img src={images.twitter} alt='twitter' className='social-icon' />
                        <img src={images.linkedin} alt='linkedin' className='social-icon' />
                        <img src={images.instagram} alt='instagram' />
                    </div>
                </div>
                <a href="//www.dmca.com/Protection/Status.aspx?ID=eea71184-71ee-4c73-a959-5852051246f6" title="DMCA.com Protection Status" class="dmca-badge"> <img src="https://images.dmca.com/Badges/dmca_protected_sml_120n.png?ID=eea71184-71ee-4c73-a959-5852051246f6" alt="DMCA.com Protection Status" /></a> <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
            </Container>
        </React.Fragment>
    )
}
export default FooterContainer;