import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import images from '../../resources/images';
import { NavLink } from "react-router-dom";
import './aboutus.css';

const AboutUs = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook

    const navigateToAboutUs = () => {
        navigate('/aboutUs'); // Navigate to '/aboutUs' when button is clicked
    };
    const [click, setClick] = useState(false);
    const handleNavLinkClick = () => {
        if (click) {
            setClick(false);
        }
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };
    return (
        <div className="containerss">
            <div className='make-corner-mobile'></div>
            <div className="lefts">
                <img src={images.about_us} alt='about' className='aboutImage mx-md-auto' />
            </div>
            <div className="rights">
                <div >
                    <h1 className='storyHeadings'>We Solutionize.</h1>
                </div>
                <p className='aboutUsTexts'>Geekonomy is a team of driven and innovative individuals, blending passion with curiosity to deliver exceptional value through design.</p>
                <p className='aboutUsTexts mt-4 '>In today's world, we embrace marketing as an integral part of life, crafting captivating experiences that seamlessly bridge the online and offline realms. Our expertise lies in empowering brands to share their unique narratives, fueling transformative growth. </p>
                <div className='mt-3 make-center'>


                    <Button className='learMoreBtns learnMoreTexts' variant="outline-light">
                        <NavLink
                            className='getintouch'
                            to="/aboutUs"
                            onClick={() => { handleNavLinkClick(); }}
                            style={{ textDecoration: 'none', color: 'white', }}
                        >
                            Learn More
                        </NavLink>
                    </Button>
                </div>
            </div>
            <div className='make-corner' >
            </div>
        </div>
    );
};

export default AboutUs;
