import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import images from '../../resources/images'
import Brands from '../home/Brands'
import { Link } from 'react-router-dom';


const SecondBlogPage = () => {
    const [click, setClick] = useState(false);
    const handleNavLinkClick = () => {
        if (click) {
            setClick(false);
        }
        window.scrollTo(0, 0);
    };
    return (
        <React.Fragment>
            <Container className='mt-5 rightContent'>
                <div className='mb-5'>
                    <img src={images.Ux_design} alt='UX Design' className='secondBlog' />
                </div>

                <h1 className='blogs-heading'> UX Design Trends for 2024: Geekonomy's Guide to Creating User-Centric Experiences</h1>
                <div className='missionText'>
                    <div >
                        <p>User experience (UX) design is a critical aspect of creating a successful digital product. In 2024,
                            several trends are shaping the field of UX design, and staying ahead of these trends is essential for
                            creating user-centric experiences that drive engagement and conversions. One of the key trends in
                            UX design for 2024 is the continued focus on accessibility. </p>
                    </div>
                    <div>
                        <p>
                            With an increasing number of users
                            accessing digital products on mobile devices and a growing awareness of the importance of
                            accessibility, designers are placing a greater emphasis on creating interfaces that are easy to use for
                            all users, including those with disabilities.
                        </p>
                    </div>
                    <div>
                        <p>
                            Another trend in UX design is the use of artificial intelligence
                            (AI) and machine learning to create more personalized and adaptive user experiences. By
                            leveraging AI, designers can create interfaces that adapt to the user's behavior and preferences,
                            providing a more tailored experience that enhances user satisfaction and engagement.
                        </p>
                    </div>
                    <div>
                        <p>
                            Additionally,
                            designers are increasingly using data-driven design principles to inform their decision-making
                            process. By analyzing user data and feedback, designers can gain valuable insights into how users
                            interact with a product and use this information to make informed design decisions that improve the
                            overall user experience.
                        </p>
                    </div>
                    <div>
                        <p>In conclusion, staying ahead of the latest UX design trends is essential for
                            creating user-centric experiences that drive engagement and conversions. At Geekonomy, we stay
                            at the forefront of UX design trends to ensure that our clients' digital products deliver a seamless and
                            engaging user experience.
                        </p>
                    </div>

                </div>

            </Container>
            <div className='mt-5 mb-5 emptySpace1'></div>
            <Container>
                <div className='mt-4'>
                    <div style={{ position: 'relative' }} className='mt-4 mb-4'>
                        {/* <img src={images.arrow_circle} alt="Circle" className='blogImgCircle' />
                        <img src={images.right_arrow} alt="Arrow" className='blogImgArrow' /> */}
                        <Link to="/thirdBlog" onClick={handleNavLinkClick}>
                            <Button className='nextBtn'>Next</Button>
                        </Link>
                    </div>
                </div>
            </Container>
            <div className='mt-5 mb-5 emptySpace'></div>
            <div className='twoContentScetion'>
                <Brands />
            </div>
        </React.Fragment>
    )
}

export default SecondBlogPage;
