import { Helmet } from "react-helmet";
import React from "react";
import images from "../../../resources/images";

const CustomerRetension = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Top Branding Agency in bangalore |geekonomy</title>
                <meta name="description" content="Bangalore's top branding agency, creating innovative strategies
        to improve the visibility of your business. We are experts in all aspects of your brand's identification,
        from digital marketing to creative design." />
                <meta name="keywords" content="branding agencies in banglore.geekonomy,digital marketing,cybersecurity services in bengaluru." />
            </Helmet>
            
            <div>
                <img src={images.Coming_soon_banner} alt="coming soon banner" style={{ width: "100%", height: "auto" }} />
            </div>
        </React.Fragment>
    )

}

export default CustomerRetension;