import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import images from '../../resources/images'
import Brands from '../home/Brands'
import { Link } from 'react-router-dom';


const FirstBlogPage = () => {

    const [click, setClick] = useState(false);
    const handleNavLinkClick = () => {
        if (click) {
            setClick(false);
        }
        window.scrollTo(0, 0);
    };
    return (
        <React.Fragment>
            <Container className='mt-5 rightContent'>
                <div className='mb-5 imageblog1' >
                    <img src={images.Brand_storytelling} alt='Brand Storytelling' className='firstBlog' />
                </div>

                <h1 className='blogs-heading'> The Power of Brand Storytelling: How Geekonomy Helps Brands Connect with Customers</h1>
                <div className='missionText'>
                    <div >
                        <p>Brand storytelling is a powerful tool that can help businesses connect with their audience on a
                            deeper level. In today's competitive market, where consumers are bombarded with advertisements
                            and marketing messages, storytelling allows brands to stand out by creating a memorable and
                            emotional connection with their customers.</p>
                    </div>
                    <div>
                        <p>
                            At Geekonomy, we understand the importance of brand
                            storytelling and help our clients craft compelling narratives that resonate with their target audience.
                            Whether it's through a brand's origin story, its mission and values, or the stories of its customers, we
                            work to create a cohesive and engaging brand narrative that sets our clients apart from their
                            competitors.
                        </p>
                    </div>
                    <div>
                        <p>
                            One of the key aspects of effective brand storytelling is authenticity. Customers today
                            are more discerning than ever and can easily spot a brand that is being inauthentic or insincere.
                            That's why we work closely with our clients to uncover the true essence of their brand and tell their
                            story in a way that is genuine and relatable.
                        </p>
                    </div>
                    <div>
                        <p>
                            Another important element of brand storytelling is
                            consistency. A brand's story should be consistent across all touch points, from its website and social
                            media channels to its advertising and customer service. This consistency helps to reinforce the
                            brand's narrative and build trust with its audience over time.
                        </p>
                    </div>
                    <div>
                        <p>
                            In conclusion, brand storytelling is a
                            powerful tool that can help businesses connect with their audience in a meaningful way. At
                            Geekonomy, we help our clients harness the power of storytelling to create compelling brand
                            narratives that resonate with their customers and set them apart from their competitors.
                        </p>
                    </div>

                </div>

            </Container>
            <div className='mt-5 mb-5 emptySpace1'></div>
            <Container>
                <div className='mt-4'>
                    <div style={{ position: 'relative' }} className='mt-4 mb-4'>
                        {/* <img src={images.arrow_circle} alt="Circle" className='blogImgCircle' />
                        <img src={images.right_arrow} alt="Arrow" className='blogImgArrow' /> */}
                        <Link to="/secondBlog" onClick={() => { handleNavLinkClick(); }}>
                            <Button className='nextBtn'>Next</Button>

                        </Link>
                    </div>
                </div>
            </Container>
            <div className='mt-5 mb-5 emptySpace'></div>
            <div className='twoContentScetion'>
                <Brands />
            </div>
        </React.Fragment>
    )
}

export default FirstBlogPage;
