import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import images from '../../resources/images'
import Brands from '../home/Brands'
import { Link } from 'react-router-dom';


const NinethBlogPage = () => {
    const [click, setClick] = useState(false);
    const handleNavLinkClick = () => {
        if (click) {
            setClick(false);
        }
        window.scrollTo(0, 0);
    };
    return (
        <React.Fragment>
            <Container className='mt-5 rightContent'>
                <div className='mb-5'>
                    <img src={images.Digital_advertising} alt='Digital Advertising Strategies' className='ninethblog' />
                </div>

                <h1 className='blogs-heading'>Digital Advertising Strategies: Geekonomy's Approach to Maximizing ROI</h1>
                <div className='missionText'>
                    <div >
                        <p>Digital advertising is a powerful tool for reaching a targeted audience and driving conversions. In
                            today's digital landscape, where consumers are spending more time online than ever before, having
                            an effective digital advertising strategy is essential for businesses looking to succeed online.
                        </p>
                    </div>
                    <div>
                        <p>
                            One of
                            the key aspects of a successful digital advertising strategy is targeting the right audience. By
                            understanding your target audience's demographics, interests, and online behavior, you can create
                            ads that are more relevant and engaging, increasing the likelihood of conversions.
                        </p>
                    </div>
                    <div>
                        <p>
                            Another important
                            aspect of digital advertising is choosing the right platforms. With so many digital advertising platforms
                            available, it's important to select the ones that are most likely to reach your target audience.
                        </p>
                    </div>
                    <div>
                        <p>
                            Whether
                            it's through social media advertising, search engine marketing, or display advertising, choosing the
                            right platforms can help you maximize your ROI and drive results.


                        </p>
                    </div>
                    <div>
                        <p>
                            Additionally, it's important to track
                            and measure the performance of your digital advertising campaigns. By analyzing key metrics such
                            as click-through rates, conversion rates, and return on ad spend, you can identify areas for
                            improvement and optimize your campaigns for better results.


                        </p>
                    </div>
                    <div>
                        <p>
                            At Geekonomy, we understand the
                            importance of a well-executed digital advertising strategy and work closely with our clients to develop
                            campaigns that drive results. From ad creative to targeting to campaign optimization, we use a
                            data-driven approach to digital advertising to help our clients maximize their ROI and achieve their
                            business goals.


                        </p>
                    </div>

                </div>

            </Container>
            <div className='mt-5 mb-5 emptySpace1'></div>
            <Container>
                <div className='mt-4'>
                    <div style={{ position: 'relative' }} className='mt-4 mb-4'>
                        {/* <img src={images.arrow_circle} alt="Circle" className='blogImgCircle' />
                        <img src={images.right_arrow} alt="Arrow" className='blogImgArrow' /> */}
                        <Link to="/tenthblog" onClick={handleNavLinkClick}>
                            <Button className='nextBtn'>Next</Button>
                        </Link>
                    </div>
                </div>
            </Container>
            <div className='mt-5 mb-5 emptySpace'></div>
            <div className='twoContentScetion'>
                <Brands />
            </div>
        </React.Fragment>
    )
}

export default NinethBlogPage;
