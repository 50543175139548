import React, { useState } from 'react'
import '../ServicesStyle.css';
import './CustomDevelopment.css';
import images from '../../../resources/images';
import { Button, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { NavLink } from "react-router-dom";
import YourComponent from './we-do';

const CustomDevelopment = () => {

    const [click, setClick] = useState(false);
    const handleNavLinkClick = () => {
        if (click) {
            setClick(false);
        }
        window.scrollTo(0, 0);
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>Best custom development services in Bangalore | Geekonomy</title>
                <meta name="description" content="Unleash Your Ideas with Custom Development. Web, mobile apps, software solutions - We bring your vision to life with expertise and innovation." />
                <meta
                    name="keywords"
                    content="best branding services, webdevelopment agencies, best customer retention services, geekonomy,best branding agency in bangalore,seo, sem best website optimisation services, web developers"
                />
            </Helmet>
            <div className="main-image-container" >
                <img
                    src={images.Custom_bg}
                    alt="branding bg"
                    className="main-image-style"
                />
                <div className="marquee-container">
                    <div className="marquee">
                        <span>You Dream it, we build it.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>
                    <div className="marquee marquee2">
                        <span>You Dream it, we build it.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>
                </div>

                <div className="custom-man" >
                    <img
                        alt="Lightbulb"
                        className=" branding-man-style"
                        src={images.Custom_girl}
                    />
                </div>
            </div>

            <div className="container py-5">
                <div>
                    <p className='missionText '>
                        Transform vision to reality, break barriers with Geekonomy's bespoke digital craftsmanship.
                    </p>
                    <p className='missionText '>
                        Embark on a journey of limitless possibility with Geekonomy's bespoke development solutions. Powered by our boundless imagination and technical expertise, we breathe life into your ideas, transforming them into digital realities that defy expectation. Whether you seek a dynamic website that dances with interactivity, an app that seamlessly integrates into users' lives, or software that empowers your enterprise to soar, our team stands ready to sculpt the future of your vision with precision and flair.
                    </p>
                </div>
                <hr />
            </div>
            <div className='mt-2'>
                <YourComponent />
            </div>
           
            <div className="d-flex flex-column align-items-center  brandingSolutionFooter ">
                <Container>
                    <h3 className='getinText text-center text-md-start largeScreen d-none d-md-block'>
                        We merge simplicity with sophistication to craft intuitive solutions. Our commitment to user-centric design ensures seamless experiences that elevate your digital presence with elegance and efficiency.

                    </h3>
                </Container>

                <p className="d-md-none serviceFooter rightContent">
                    We specialize in creating cohesive brand identities that resonate with your audience.From
                    logo design to brand strategy, we work closely with you to bring your vision to life.
                </p>
                <div className="mt-5">
                    <Button className='aboutUsBtn' variant="light">
                        <NavLink
                            to="/ContactUs"
                            onClick={() => { handleNavLinkClick(); }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            Get In Touch
                        </NavLink>
                    </Button>
                </div>
            </div>
        </React.Fragment >
    )
}

export default CustomDevelopment
