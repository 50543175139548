import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import mask1 from "../../../assets/service/mask1.png";
import mask2 from "../../../assets/service/mask2.png";
import mask3 from "../../../assets/service/mask3.png";
import mask4 from "../../../assets/service/mask4.png";
import mask5 from "../../../assets/service/mask5.png";
import mask6 from "../../../assets/service/mask6.png";
import 'bootstrap/dist/css/bootstrap.min.css';

const YourComponent = () => {
    return (
        <Container className="px-2">
            <h1 className='text-center text-4xl md:text-5xl font-medium text-white  mb-5' style={{ lineHeight: 1.3 }}>How we build</h1>
            <Row className='mt-10 mb-10 md:mt-20'>
            <Col md={6} className="mb-4 mb-md-5 order-md-1">
                    <img src={mask1} alt="" className='object-cover mb-4 img-fluid' style={{ maxWidth: '160px' }} />
                    <div className='pr-0 sm:pr-10'>
                        <h1 className='text-white text-2xl font-extrabold mb-4'>Discovery & Analysis</h1>
                        <p className='text-white text-xl font-light'>You can’t brainstorm, much less build, a great solution without first investing in discovery and analysis. Our team burrows into your existing infrastructure to uncover strengths and weaknesses so isn’t wasted during the design and build phase.</p>
                    </div>
                </Col>
                <Col md={6} className="mb-4 mb-md-5 order-md-2" >
                    <img src={mask2} alt="" className='object-cover mb-4 img-fluid' style={{ maxWidth: '160px' }} />
                    <div className='pr-0 sm:pr-10'>
                        <h1 className='text-white text-2xl font-extrabold mb-4'>UI/UX Design</h1>
                        <p className='text-white text-xl font-light'>At its core, UX and UI Design should be elegant, intuitive, and frictionless. With a diverse portfolio of successful design projects to our name, we know how to build solutions that are stylish and aesthetically pleasing - and that help you meet your business goals.</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mb-4 mb-md-5">
                    <img src={mask3} alt="" className='object-cover mb-4 img-fluid' style={{ maxWidth: '160px' }} />
                    <div className='pr-0 sm:pr-10'>
                        <h1 className='text-white text-2xl font-extrabold mb-4'>Development</h1>
                        <p className='text-white text-xl font-light'>You can’t brainstorm, much less build, a great solution without first investing in discovery and analysis. Our team burrows into your existing infrastructure to uncover strengths and weaknesses so isn’t wasted during the design and build phase.</p>
                    </div>
                </Col>
                <Col md={6} className="mb-4 mb-md-5">
                    <img src={mask4} alt="" className='object-cover mb-4 img-fluid' style={{ maxWidth: '160px' }} />
                    <div className='pr-0 sm:pr-10'>
                        <h1 className='text-white text-2xl font-extrabold mb-4'>Feedback & testing</h1>
                        <p className='text-white text-xl font-light'>At Geekonomy, the feedback and testing process are transparent. With regular status meetings and routine check-ins by video, email, and chat, we take your ongoing feedback into account every step of the way.</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="mb-4 mb-md-5">
                    <img src={mask5} alt="" className='object-cover mb-4 img-fluid' style={{ maxWidth: '160px' }} />
                    <div className='pr-0 sm:pr-10'>
                        <h1 className='text-white text-2xl font-extrabold mb-4'>Release</h1>
                        <p className='text-white text-xl font-light'>Software/Website release isn’t a single moment; it’s a process. As you roll out what we’ve built, we make sure that the solution works in sync with your existing systems and processes.</p>
                    </div>
                </Col>
                <Col md={6} className="mb-4 mb-md-5">
                    <img src={mask6} alt="" className='object-cover mb-4 img-fluid' style={{ maxWidth: '160px' }} />
                    <div className='pr-0 sm:pr-10'>
                        <h1 className='text-white text-2xl font-extrabold mb-4'>Refinement</h1>
                        <p className='text-white text-xl font-light'>Many custom developers take the “love ‘em and leave ’em” approach to their work. They land the contract, do the work and then move on. But with Geekonomy, the developers who build your website or software stay on active standby after its release to optimize & iterate, so that the solution grows with you.</p>
                    </div>
                </Col>
            </Row>


        </Container>
    );
}

export default YourComponent;
