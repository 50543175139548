import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import images from '../../resources/images'
import Brands from '../home/Brands'
import { Link } from 'react-router-dom';


const EighthBlogPage = () => {

    const [click, setClick] = useState(false);
    const handleNavLinkClick = () => {
        if (click) {
            setClick(false);
        }
        window.scrollTo(0, 0);
    };
    return (
        <React.Fragment>
            <Container className='mt-5 rightContent'>
                <div className='mb-5'>
                    <img src={images.Stunning_logos} alt='Geekonomy Designs Stunning Logos' className='eighthblog' />
                </div>

                <h1 className='blogs-heading'> How Geekonomy Designs Stunning Logos that Stand Out in a Crowded Market</h1>
                <div className='missionText'>
                    <div >
                        <p >A logo is a critical aspect of branding, as it serves as the visual representation of a brand and helps
                            to create a memorable and recognizable identity. In today's crowded market, where consumers are
                            bombarded with advertisements and marketing messages, having a standout logo is essential for
                            setting your brand apart from the competition.</p>
                    </div>
                    <div>
                        <p>
                            At Geekonomy, we understand the importance of
                            logo design and work closely with our clients to create stunning logos that capture the essence of
                            their brand. Our logo design process begins with a thorough understanding of our client's brand
                            Identity, values, and target audience, allowing us to create a logo that is not only visually appealing
                            but also meaningful and impactful.
                        </p>
                    </div>
                    <div>
                        <p>
                            One of the key aspects of our logo design process is simplicity.
                            We believe that a logo should be simple and easily recognizable, making it easy for consumers to
                            remember and associate with your brand. By focusing on simplicity, we are able to create logos that
                            are timeless and effective, standing the test of time and remaining relevant for years to come.

                        </p>
                    </div>
                    <div>
                        <p>
                            Additionally, we understand the importance of versatility in logo design. A logo should be able to be
                            used across a variety of applications, from business cards to websites to social media profiles. That's
                            why we create logos that are scalable and adaptable, ensuring that they look great no matter where
                            they are used.
                        </p>
                    </div>
                    <div>
                        <p> In conclusion, logo design is a critical aspect of branding, and having a standout
                            logo is essential for setting your brand apart from the competition. At Geekonomy, we specialize in
                            creating stunning logos that capture the essence of our client's brand and help them stand out in a
                            crowded market.
                        </p>
                    </div>


                </div>

            </Container>
            <div className='mt-5 mb-5 emptySpace1'></div>
            <Container>
                <div className='mt-4'>
                    <div style={{ position: 'relative' }} className='mt-4 mb-4'>
                        {/* <img src={images.arrow_circle} alt="Circle" className='blogImgCircle' />
                        <img src={images.right_arrow} alt="Arrow" className='blogImgArrow' /> */}
                        <Link to="/ninethblog" onClick={handleNavLinkClick}>
                            <Button className='nextBtn'>Next</Button>
                        </Link>
                    </div>
                </div>
            </Container>
            <div className='mt-5 mb-5 emptySpace'></div>
            <div className='twoContentScetion'>
                <Brands />
            </div>
        </React.Fragment>
    )
}

export default EighthBlogPage;
