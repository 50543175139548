import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import images from '../../resources/images'
import Brands from '../home/Brands'
import { Link } from 'react-router-dom';


const ThirdBlogPage = () => {
    const [click, setClick] = useState(false);
    const handleNavLinkClick = () => {
        if (click) {
            setClick(false);
        }
        window.scrollTo(0, 0);
    };
    return (
        <React.Fragment>
            <Container className='mt-5 rightContent'>
                <div className='mb-5'>
                    <img src={images.Responsive_webdesign} alt='Responsive Web Design' className='thirdBlog' />
                </div>

                <h1 className='blogs-heading'>Why Responsive Web Design is Essential: Geekonomy's Approach to Mobile Optimization</h1>
                <div className='missionText'>
                    <div >
                        <p>With the increasing use of mobile devices to access the Internet, having a responsive web design is
                            essential for ensuring that your website is accessible and user-friendly on all devices. Responsive web
                            design is a design approach that ensures that a website adapts to the screen size and device type
                            of the user, providing an optimal viewing experience. </p>
                    </div>
                    <div>
                        <p>
                            One of the key benefits of responsive web
                            design is improved user experience. By ensuring that your website is responsive, you can provide
                            users with a seamless browsing experience across all devices, which can help to increase
                            engagement and reduce bounce rates.
                        </p>
                    </div>
                    <div>
                        <p>
                            Additionally, responsive web design can also have a
                            positive impact on your website's SEO performance. Google has stated that responsive design is its
                            recommended design pattern, and websites that are mobile-friendly are more likely to rank higher in
                            search engine results pages. At Geekonomy, we understand the importance of responsive web
                            design and work closely with our clients to ensure that their websites are optimized for mobile
                            devices.
                        </p>
                    </div>
                    <div>
                        <p>
                            Our approach to mobile optimization includes designing flexible layouts, optimizing images
                            and videos for mobile viewing, and ensuring fast loading times on mobile devices. In conclusion,
                            responsive web design is essential for ensuring that your website is accessible and user-friendly on all
                            devices.
                        </p>
                    </div>
                    <div>
                        <p>At Geekonomy, we specialize in creating responsive websites that provide an optimal
                            browsing experience for users on mobile devices, helping our clients to reach a wider audience and
                            achieve their business goals. Web design is essential for ensuring that your website is accessible and
                            user-friendly on all devices.
                        </p>
                    </div>
                    <div>
                        <p>
                            At Geekonomy, we specialize in creating responsive websites that provide
                            an optimal browsing experience for users on mobile devices, helping our clients to reach a wider
                            audience and achieve their business goals.

                        </p>
                    </div>

                </div>

            </Container>
            <div className='mt-5 mb-5 emptySpace1'></div>
            <Container>
                <div className='mt-4'>
                    <div style={{ position: 'relative' }} className='mt-4 mb-4'>
                        {/* <img src={images.arrow_circle} alt="Circle" className='blogImgCircle' /> */}
                        {/* <img src={images.right_arrow} alt="Arrow" className='blogImgArrow' /> */}
                        <Link to="/fourthBlog" onClick={handleNavLinkClick}>
                            <Button className='nextBtn'>Next</Button>
                        </Link>
                    </div>
                </div>
            </Container>
            <div className='mt-5 mb-5 emptySpace'></div>
            <div className='twoContentScetion'>
                <Brands />
            </div>
        </React.Fragment>
    )
}

export default ThirdBlogPage;
