import { Accordion, Container } from 'react-bootstrap';
import './CyberSecurity.css';
function CyberSecurityAbout() {
    return (
        <Container className="main-background">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Risk Assessment and Analysis</Accordion.Header>
                    <Accordion.Body>
                        <ul>
                            <li>Conducting an in-depth risk assessment to identify potential vulnerabilities in your digital infrastructure.</li>
                        </ul>
                        <ul>
                            <li>Establishing the groundwork for a personalized and robust cybersecurity strategy.</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Endpoint Security</Accordion.Header>
                    <Accordion.Body>
                        <ul>
                            <li>It focuses on protecting the devices or "endpoints" that connect to a network, such as laptops, desktops, smartphones, and tablets.</li>
                        </ul>
                        <ul>
                            <li>It involves implementing measures like antivirus software, firewalls, encryption, and intrusion detection systems to safeguard these endpoints from cyber threats like malware, ransomware, phishing attacks, and unauthorized access. </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Data Encryption</Accordion.Header>
                    <Accordion.Body>
                        <ul>
                            <li>It is a security technique that converts data into a coded format that can only be accessed or deciphered with the correct decryption key.</li>
                        </ul>
                        <ul>
                            <li>Encryption is widely used to protect data transmitted over networks, stored on devices, or stored in the cloud.</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Incident Response Planning</Accordion.Header>
                    <Accordion.Body>
                        <ul>
                            <li>It involves creating strategies and procedures to effectively manage and mitigate cybersecurity incidents when they occur. </li>
                        </ul>
                        <ul>
                            <li>It includes identifying potential security threats, establishing protocols for detecting and responding to incidents, assigning roles and responsibilities to key personnel, and outlining steps to contain, eradicate, and recover from security breaches or cyberattacks.</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Employee Training and Awareness</Accordion.Header>
                    <Accordion.Body>
                        <ul>
                            <li>Employee training and awareness programs are essential components of cybersecurity strategies aimed at strengthening an organization's security posture. </li>
                        </ul>
                        <ul>
                            <li>By raising awareness and providing employees with the knowledge and skills to recognize and respond to cybersecurity threats, organizations can significantly reduce the likelihood of successful cyberattacks and data breaches.</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Continuous Monitoring</Accordion.Header>
                    <Accordion.Body>
                        <ul>
                            <li>It involves the real-time or near real-time observation of an organization's systems, networks, and digital assets to identify and respond to security threats and vulnerabilities promptly.</li>
                        </ul>
                        <ul>
                            <li>It is an essential component of a comprehensive cybersecurity strategy, providing organizations with the visibility and situational awareness needed to effectively protect against evolving cyber threats.</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    );
}
export default CyberSecurityAbout;