import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const TermsAndConditions = () => {
  return (
    <Container fluid style={{ backgroundColor: '#000', color: '#fff', minHeight: '100vh', padding: '20px' }}>
      <Row>
        <Col>
          <Card style={{ backgroundColor: '#000', color: '#fff', borderColor: '#333' }}>
            <Card.Body>
              <Card.Title as="h1">Terms and Conditions</Card.Title>
              <Card.Text>Last updated: August 14, 2024</Card.Text>

              <h2>Interpretation and Definitions</h2>
              <h3>Interpretation</h3>
              <p>
                The words of which the initial letter is capitalized have meanings defined
                under the following conditions. The following definitions shall have the
                same meaning regardless of whether they appear in singular or in plural.
              </p>

              <h3>Definitions</h3>
              <p>For the purposes of these Terms and Conditions:</p>
              <ul>
                <li>
                  <strong>Affiliate</strong> means an entity that controls, is controlled by or is under
                  common control with a party, where "control" means ownership of 50% or more of the shares,
                  equity interest or other securities entitled to vote for election of directors or other
                  managing authority.
                </li>
                <li>
                  <strong>Country</strong> refers to: Karnataka, India
                </li>
                <li>
                  <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this
                  Agreement) refers to Geekonomy Technology Pvt. Ltd., 24, 1st Floor, 1st Cross, Magrath Road, Bengaluru, Karnataka 560 025.
                </li>
                <li>
                  <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                </li>
                <li>
                  <strong>Service</strong> refers to the Website.
                </li>
                <li>
                  <strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.
                </li>
                <li>
                  <strong>Third-party Social Media Service</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.
                </li>
                <li>
                  <strong>Website</strong> refers to Geekonomy Technology Pvt. Ltd., accessible from <a href="https://geekonomy.in/" style={{ color: '#1e90ff' }}>https://geekonomy.in/</a>
                </li>
                <li>
                  <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                </li>
              </ul>

              <h2>Acknowledgment</h2>
              <p>
                These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
              </p>

              <h2>Links to Other Websites</h2>
              <p>
                Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company. 
              </p>

              <h2>Termination</h2>
              <p>
                We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions. 
              </p>

              <h2>Limitation of Liability</h2>
              <p>
                Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service. 
              </p>

              <h2>"AS IS" and "AS AVAILABLE" Disclaimer</h2>
              <p>
                The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind.
              </p>

              <h2>Governing Law</h2>
              <p>
                The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service.
              </p>

              <h2>Disputes Resolution</h2>
              <p>
                If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.
              </p>

              <h2>Changes to These Terms and Conditions</h2>
              <p>
                We reserve the right, at Our sole discretion, to modify or replace these Terms at any time.
              </p>

              <h2>Contact Us</h2>
              <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
              <ul>
                <li>By email: info@geekonomy.in</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsAndConditions;
