import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import images from '../../resources/images'
import Brands from '../home/Brands'
import { Link } from 'react-router-dom';


const SeventhBlogPage = () => {
    const [click, setClick] = useState(false);
    const handleNavLinkClick = () => {
        if (click) {
            setClick(false);
        }
        window.scrollTo(0, 0);
    };
    return (
        <React.Fragment>
            <Container className='mt-5 rightContent'>
                <div className='mb-5'>
                    <img src={images.Importance_of_seo} alt='Importance of SEO' className='seventhblog' />
                </div>

                <h1 className='blogs-heading'>The Importance of SEO in Today's Digital Landscape: Geekonomy's Best Practices</h1>
                <div className='missionText'>
                    <div >
                        <p>Search engine optimization (SEO) is a critical component of any digital marketing strategy, as it helps
                            to improve a website's visibility in search engine results pages (SERP’s) and drive organic traffic. In
                            today's digital landscape, where consumers are increasingly turning to search engines to find
                            products and services, having a strong SEO strategy is essential for businesses looking to succeed
                            online.
                        </p>
                    </div>
                    <div>
                        <p>
                            One of the key benefits of SEO is that it can help to increase organic traffic to your website. By
                            optimizing your website for relevant keywords and creating high-quality, informative content, you can
                            improve your chances of appearing in search results for relevant queries, driving more traffic to your
                            site and increasing brand awareness.
                        </p>
                    </div>
                    <div>
                        <p>
                             Additionally, SEO can help to improve the user experience on
                            your website. By optimizing your site's navigation, improving page load times, and ensuring that your
                            content is easy to read and navigate, you can create a better experience for your users and
                            encourage them to spend more time on your site.
                        </p>
                    </div>
                    <div>
                        <p>
                            At Geekonomy, we understand the importance of
                            SEO in today's digital landscape and work closely with our clients to develop SEO strategies that drive
                            results. From keyword research to on-page optimization to link building, we use a holistic approach to
                            SEO to help our clients improve their search engine rankings and drive organic traffic to their website.


                        </p>
                    </div>

                </div>

            </Container>
            <div className='mt-5 mb-5 emptySpace1'></div>
            <Container>
                <div className='mt-4'>
                    <div style={{ position: 'relative' }} className='mt-4 mb-4'>
                        {/* <img src={images.arrow_circle} alt="Circle" className='blogImgCircle' />
                        <img src={images.right_arrow} alt="Arrow" className='blogImgArrow' /> */}
                        <Link to="/eighthblog" onClick={handleNavLinkClick}>
                            <Button className='nextBtn'>Next</Button>
                        </Link>
                    </div>
                </div>
            </Container>
            <div className='mt-5 mb-5 emptySpace'></div>
            <div className='twoContentScetion'>
                <Brands />
            </div>
        </React.Fragment>
    )
}

export default SeventhBlogPage;
