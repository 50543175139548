import React, { useState } from "react";
import './ContactUs.css';
import images from "../../resources/images";
import axios from "axios";
import { toast } from 'react-toastify'

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    selectSubject: "General Inquiry",
    message: ''
  })

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCheckboxChange = (subject) => {
    setFormData({
      ...formData,
      selectSubject: subject
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await axios.post('https://geekonomyemailer-viyv.onrender.com/send-email', formData);
      console.log("Result>", result);
      toast.success(result);
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };
  return (
    <div style={{backgroundImage: `url(${images.contactus_bg})`}}>
      <div class="container">
        <div class="row justify-content-center mt-4">
          <div class="col-12 col-md-8 text-center">
            <p class="heading-text display-4 ">
              Stories that scream success
              are now just a call away!
            </p>
          </div>
        </div>
        <div className="form-background">
          <img className="dekstop-only" src={images.contactus_form_bg} alt="contact us form bg" />
          <img src={images.mobileForm} alt="contact us form bg" className="mobile-only" />
          <div className="form-data">
            <div className="left-side-form">
              <div className="left-head">
                <p>Get a Free Marketing Consultation  <br></br>
                  For Your Business</p>
              </div>
              <div className="left-phone">
                <img src={images.phone_call} alt="phone call"></img>
                <p>+91 7624818717</p>
              </div>
              <div className="left-email">
                <img src={images.email_symbol} alt="email"></img>
                <p>info@geekonomy.in</p>
              </div>
              <div className="left-location">
                <img src={images.location_symbol} alt="location"></img>
                <p>24, 1st Floor, 1st Cross, Magrath Road, <br></br>
                  Bengaluru, Karnataka 560 025.
                </p>
              </div>
              <div className="left-locations">
                <img src={images.location_symbol} alt="location" className="location-logo"></img>
                <p>RET-R5-054, Detached Retail R5, JLT- <br></br>
                  PH2- RET-R5, <br></br>
                  Jumeirah Lakes Towers, Dubai, United <br></br>
                  Arab Emirates.
                </p>
              </div>
              <div className="left-icons">
                <div className="left-twiiter">
                  <img src={images.x_symbol} alt="twitter"></img>
                </div>
                <div className="left-insta">
                  <img src={images.instagram_symbol} alt="insta"></img>
                </div>
                <div className="left-facebook">
                  <img src={images.facebook_symbol} alt="facebook"></img>
                </div>
              </div>
            </div>

            <div className="right-side-form">
              <div className="firt-section-form">
                <div class="first-name form-style">
                  <p>First name</p>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={(e) => handleInputChange(e)}
                    className="placeholder-color"
                  />
                </div>
                <div class="last-name form-style">
                  <p>Last Name</p>
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={(e) => handleInputChange(e)}
                    className="placeholder-color"
                  />

                </div>

              </div>

              <div className="firt-section-form">
                <div className="email form-style">
                  <p>Email</p>
                  <input
                    type="text"
                    name="email"
                    placeholder="example@gmail.com"
                    value={formData.email}
                    onChange={(e) => handleInputChange(e)}
                    className="placeholder-color"
                  />
                </div>

                <div className="last-name form-style">
                  <p>Phone Number</p>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="+95 54654652"
                    value={formData.phoneNumber}
                    onChange={(e) => handleInputChange(e)}
                    className="placeholder-color"
                  />
                </div>
              </div>

              <div className="subject-style">
                <p>Select Subject?</p>

                <input
                  type="checkbox"
                  value="General enquiry"
                  checked={formData.selectSubject === "General Inquiry"}
                  onChange={() => handleCheckboxChange("General Inquiry")}
                  className="custom-checkbox"
                />
                <span className="checkbox-text ">General Inquiry</span>

                <input
                  type="checkbox"
                  value="Digital Marketing"
                  checked={formData.selectSubject === "Digital Marketing"}
                  onChange={() => handleCheckboxChange("Digital Marketing")}
                  className="custom-checkbox"
                />
                <span className="checkbox-text checkbox-text-extra">Digital Marketing</span>

                <input
                  type="checkbox"
                  value="Cyber Security"
                  checked={formData.selectSubject === "Cyber Security"}
                  onChange={() => handleCheckboxChange("Cyber Security")}
                  className="custom-checkbox"
                />
                <span className="checkbox-text"> Cyber Security</span>

                <input
                  type="checkbox"
                  value="Branding"
                  checked={formData.selectSubject === "Branding"}
                  onChange={() => handleCheckboxChange("Branding")}
                  className="custom-checkbox"
                />
                <span className="checkbox-text">Branding</span>
              </div>

              <div className="message-field form-style form-style1">
                <p>Message</p>
                <input
                  type="text"
                  name="message"
                  placeholder="Write your message.."
                  value={formData.message}
                  onChange={(e) => handleInputChange(e)}
                  className="placeholder-color placeholder-color1"
                />
              </div>
              <div class="containersend-message">
                <button className="send-message" type="submit" onClick={handleSubmit}>Send Message</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="centered">
          <p class="heading-text-mobile">
            Stories that scream success are now just a call away!
          </p>
        </div>
        <div className="right-side-form-mobile">
          <div className="firt-section-form-mobile">
            <div class="first-name-mobile form-style-mobile">
              <p>First name</p>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={(e) => handleInputChange(e)}
                className="placeholder-color-mobile"
              />
            </div>
            <div class="last-name-mobile form-style-mobile">
              <p>Last Name</p>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={(e) => handleInputChange(e)}
                className="placeholder-color-mobile"
              />
            </div>
          </div>
          <div className="firt-section-form-mobile">
            <div className="email form-style-mobile">
              <p>Email</p>
              <input
                type="text"
                name="email"
                placeholder="example@gmail.com"
                value={formData.email}
                onChange={(e) => handleInputChange(e)}
                className="placeholder-color-mobile"
              />
            </div>
            <div className="last-name-mobile form-style-mobile">
              <p>Phone Number</p>
              <input
                type="text"
                name="phoneNumber"
                placeholder="+95 54654652"
                value={formData.phoneNumber}
                onChange={(e) => handleInputChange(e)}
                className="placeholder-color-mobile"
              />
            </div>
          </div>
          <div className="subject-style-mobile">
            <p>Select Subject?</p>
            <div>
              <input
                type="checkbox"
                value="General enquiry"
                checked={formData.selectSubject === "General Inquiry"}
                onChange={() => handleCheckboxChange("General Inquiry")}
                className="custom-checkbox-mobile"
              />
              <span className="checkbox-text-mobile ">General Inquiry</span>
            </div>
            <div>
              <input
                type="checkbox"
                value="Digital Marketing"
                checked={formData.selectSubject === "Digital Marketing"}
                onChange={() => handleCheckboxChange("Digital Marketing")}
                className="custom-checkbox-mobile"
              />
              <span className="checkbox-text-mobile checkbox-text-extra-mobile">Digital Marketing</span>
            </div>
            <div>
              <input
                type="checkbox"
                value="Cyber Security"
                checked={formData.selectSubject === "Cyber Security"}
                onChange={() => handleCheckboxChange("Cyber Security")}
                className="custom-checkbox-mobile"
              />
              <span className="checkbox-text-mobile"> Cyber Security</span>
            </div>
            <div>
              <input
                type="checkbox"
                value="Branding"
                checked={formData.selectSubject === "Branding"}
                onChange={() => handleCheckboxChange("Branding")}
                className="custom-checkbox-mobile"
              />
              <span className="checkbox-text-mobile">Branding</span>
            </div>
          </div>
          <div className="message-field-mobile form-style-mobile">
            <p>Message</p>
            <input
              type="text"
              name="message"
              placeholder="Write your message.."
              value={formData.message}
              onChange={(e) => handleInputChange(e)}
              className="placeholder-color-mobile"
            />
          </div>
          <div class="containersend-message-mobile">
            <button className="send-message-mobile" type="submit" onClick={handleSubmit}>Send Message</button>
          </div>
        </div>
        <div className="left-side-form-mobile">
          <div className="left-head-mobile">
            <p>Get a Free Marketing Consultation
              For Your Business</p>
          </div>
          <div className="left-phone-mobile">
            <img src={images.phone_call} alt="phone call"></img>
            <p>+91 7624818717</p>
          </div>
          <div className="left-email-mobile">
            <img src={images.email_symbol} alt="email"></img>
            <p>info@geekonomy.in</p>
          </div>
          <div className="left-location-mobile">
            <img src={images.location_symbol} alt="location"></img>
            <p>24, 1st Floor, 1st Cross, Magrath Road,
              Bengaluru, Karnataka 560 025.
            </p>
          </div>
          <div className="left-locations-mobile">
            <img src={images.location_symbol} alt="location" className="location-logo"></img>
            <p>RET-R5-054, Detached Retail R5, JLT-
              PH2- RET-R5,
              Jumeirah Lakes Towers, Dubai, United
              Arab Emirates.
            </p>
          </div>
          <div className="left-icons-mobile">
            <div className="left-twiiter-mobile">
              <img src={images.x_symbol} alt="twitter"></img>
            </div>
            <div className="left-insta-mobile">
              <img src={images.instagram_symbol} alt="insta"></img>
            </div>
            <div className="left-facebook-mobile">
              <img src={images.facebook_symbol} alt="facebook"></img>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
};
export default ContactUs;