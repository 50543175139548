import React, { useState, useRef } from 'react';
import images from '../../resources/images';
import 'react-slideshow-image/dist/styles.css';
import './imagesSlid.css';
const ImageSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
        {
            imageUrl: images.Brand_storytelling,
            heading: 'The Power of Brand Storytelling: How Geekonomy Helps Brands Connect with Customers',
            para:'Brand storytelling is a powerful tool that can help businesses connect with their audience on a deeper level. In todays competitive market, where consumers are bombarded with advertisements and marketing messages, storytelling allows brands to stand out by creating a memorable and emotional connection with their customers' ,
            linkBlog: "/firstBlog"
        },
        {
            imageUrl: images.Ux_design,
            heading: 'UX Design Trends for 2024: Geekonomys Guide to Creating User-Centric Experiences',
            para: 'User experience (UX) design is a critical aspect of creating a successful digital product. In 2024,several trends are shaping the field of UX design, and staying ahead of these trends is essential for creating user-centric experiences that drive engagement and conversions. One of the key trends in UX design for 2024 is the continued focus on accessibility',
            linkBlog: "/secondBlog"
        },
        {
            imageUrl: images.Responsive_webdesign,
            heading: 'Why Responsive Web Design is Essential: Geekonomys Approach to Mobile Optimization',
            para: 'With the increasing use of mobile devices to access the Internet, having a responsive web design is essential for ensuring that your website is accessible and user-friendly on all devices. Responsive web design is a design approach that ensures that a website adapts to the screen size and device type of the user, providing an optimal viewing experience',
            linkBlog: "/thirdBlog"
        },
        {
            imageUrl: images.E_commerce,
            heading: 'The Future of E-Commerce: Geekonomys Strategies for Online Success',
            para: 'E-commerce has undergone significant changes in recent years, driven by advancements in technology and changing consumer behavior. As we look ahead to the future of e-commerce, several trends are shaping the industry and presenting new opportunities for online retailers. One of the key trends in e-commerce is the rise of omni-channel shopping experiences',
            linkBlog: "/fourthBlog"
        },
        {
            imageUrl:images.Content_marketing,
            heading: 'Content Marketing in 2024: Geekonomys Tips for Creating Engaging Content',
            para: 'Content marketing continues to be a powerful tool for brands looking to engage with their audience and drive traffic to their website. In 2024, several trends are shaping the world of content marketing, and staying ahead of these trends is essential for creating content that resonates with your target audience',
            linkBlog: "/fifthBlog"
        },
        {
            imageUrl:images.Social_media_marketing,
            heading: 'Social Media Marketing Trends to Watch: Geekonomys Insights for Effective Campaigns',
            para: 'Social media marketing is a constantly evolving field, with new trends and technologies shaping the way that brands engage with their audience. In 2024, several trends are shaping the world of social media marketing, and staying ahead of these trends is essential for creating effective campaigns that drive engagement and conversions',
            linkBlog: "/sixthblog"
        },
        {
            imageUrl:images.Importance_of_seo,
            heading: 'The Importance of SEO in Todays Digital Landscape: Geekonomys Best Practices',
            para: 'Search engine optimization (SEO) is a critical component of any digital marketing strategy, as it helps to improve a websites visibility in search engine results pages (SERP’s) and drive organic traffic. In todays digital landscape, where consumers are increasingly turning to search engines to find products and services, having a strong SEO strategy is essential for businesses looking to succeed online',
            linkBlog: "/seventhblog"
        },
        {
            imageUrl:images.Stunning_logos,
            heading: ' How Geekonomy Designs Stunning Logos that Stand Out in a Crowded Market',
            para: 'A logo is a critical aspect of branding, as it serves as the visual representation of a brand and helps to create a memorable and recognizable identity. In todays crowded market, where consumers are bombarded with advertisements and marketing messages, having a standout logo is essential for setting your brand apart from the competition',
            linkBlog: "/eighthblog"
        },
        {
            imageUrl:images.Digital_advertising,
            heading: 'Digital Advertising Strategies: Geekonomys Approach to Maximizing ROI',
            para: 'Digital advertising is a powerful tool for reaching a targeted audience and driving conversions. In todays digital landscape, where consumers are spending more time online than ever before, having an effective digital advertising strategy is essential for businesses looking to succeed online',
            linkBlog: "/ninethblog"
        },
        {
            imageUrl:images.Ai_marketing,
            heading: 'The Role of AI in Marketing: Geekonomys Guide to Leveraging Technology for Success',
            para: 'Artificial intelligence (AI) is transforming the field of marketing, enabling businesses to analyze data, automate tasks, and personalize customer experiences at scale. In todays competitive market, where consumers expect personalized and relevant interactions with brands, AI has become an indispensable tool for marketers looking to succeed',
            linkBlog: "/tenthblog"
        }
    ];
    const imageRef = useRef(null);
    const contentRef = useRef(null);
    const contentParaRef = useRef(null);
    const contentReadRef = useRef(null);
    const contentReadSymbolRef = useRef(null);
    const handlePrevSlide = () => {
        setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
        imageRef.current.style.animation = 'none';
        contentRef.current.style.animation = 'none';
        contentParaRef.current.style.animation = 'none';
        contentReadRef.current.style.animation = 'none';
        contentReadSymbolRef.current.style.animation = 'none';
        requestAnimationFrame(() => {
            imageRef.current.style.animation = 'slideFromLeft 1.5s ease-in-out';
            contentRef.current.style.animation = 'slideFromLeft 1.5s ease-in-out';
            contentParaRef.current.style.animation = 'slideFromLeft 1.5s ease-in-out';
            contentReadRef.current.style.animation = 'slideFromLeft 1.5s ease-in-out';
            contentReadSymbolRef.current.style.animation = 'slideFromLeft 1.5s ease-in-out';
        });
    };
    const handleNextSlide = () => {
        setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
        imageRef.current.style.animation = 'none';
        contentRef.current.style.animation = 'none';
        contentParaRef.current.style.animation = 'none';
        contentReadRef.current.style.animation = 'none';
        contentReadSymbolRef.current.style.animation = 'none';
        requestAnimationFrame(() => {
            imageRef.current.style.animation = 'slideFromRight 1.5s ease-in-out';
            contentRef.current.style.animation = 'slideFromRight 1.5s ease-in-out';
            contentParaRef.current.style.animation = 'slideFromRight 1.5s ease-in-out';
            contentReadRef.current.style.animation = 'slideFromRight 1.5s ease-in-out';
            contentReadSymbolRef.current.style.animation = 'slideFromRight 1.5s ease-in-out';
        });
    };
    return (
        <div>
            <div className="slider-container-image">
                <div className='left-arrow'>
                    <img src={images.left_arrow} alt='left arrow' onClick={handlePrevSlide} />
                </div>
                <img
                    src={slides[currentSlide].imageUrl}
                    alt={`slider${currentSlide + 1}`}
                    className="slider-image-style"
                    ref={imageRef}
                />
                <div className='text-container'>
                    <h5 className="text-container-heading" ref={contentRef}>{slides[currentSlide].heading}</h5>
                    <p className="text-container-para" ref={contentParaRef}>{slides[currentSlide].para}</p>
                    <div className='read-more-container-image' ref={contentReadRef}>
                        Read more
                        <a href={slides[currentSlide].linkBlog} className="readMore">
                            <img ref={contentReadSymbolRef} src={images.read_more_arrow} alt='readmore' className='readMoreIcon' />
                        </a>
                    </div>
                </div>
                <div className='right-arrow'>
                    <img src={images.right_arrow} alt='right arrow' className='right-arrow' onClick={handleNextSlide} />
                </div>
            </div>
            <div className='text-container-mobile'>
                <h5 className="text-container-heading">{slides[currentSlide].heading}</h5>
                <p className="text-container-para">{slides[currentSlide].para}</p>
                <div className='read-more-container-image'>
                    <span className='readMore'>Read more</span>
                    <img src={images.read_more_arrow} alt='readmore' className='readMoreIcon' />
                </div>
            </div>
        </div>
    )
}
export default ImageSlider;