import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarContainer from './components/navbar/NavbarContainer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeContainer from './components/home/HomeContainer';
import FooterContainer from './components/footer/FooterContainer';
import BlogContainer from './components/blog/BlogContainer';
import ServiceContainer from './components/services/ServiceContainer';
import AboutUsContainer from './components/aboutUS/AboutUsContainer';
import FirstBlogPage from './components/blog/FirstBlogPage';
import DigitalMarketing from './components/services/digitalMarketing/DigitalMarketing';
import CustomDevelopment from './components/services/customDevelopment/CustomDevelopment';
import BrandingSolutions from './components/services/branding/BrandingSolutions'
import ContactUs from './components/contactUs/ContactUs';
import CyberSecurity from './components/services/cyberSecurity/CyberSecurity';
import CustomerRetension from "./components/services/CustomerRetension/CustomerRetension";
import SecondBlogPage from './components/blog/SecondBlogPage';
import ThirdBlogPage from './components/blog/ThirdBlogPage';
import FourthBlogPage from './components/blog/FourthBlogPage';
import FifthBlogPage from './components/blog/FifthBlogPage';
import SixthBlogPage from './components/blog/SixthBlogPage';
import SeventhBlogPage from './components/blog/SeventhBlogPage';
import EighthBlogPage from './components/blog/EighthBlogPage';
import NinethBlogPage from './components/blog/NinethBlogPage';
import TenthBlogPage from './components/blog/TenthBlogPage';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
import TermsAndConditions from './components/privacyPolicy/TermsAndConditions';
function App() {
  return (
    <div style={{ backgroundColor: '#000' }}>
      <Router>
        <NavbarContainer />
        <Routes>
          <Route path="/blog" element={<BlogContainer />} />
          <Route path="/" element={<HomeContainer />} />
          <Route path="/services" element={<ServiceContainer />} />
          <Route path="/aboutUs" element={<AboutUsContainer />} />
          <Route path="/firstBlog" element={<FirstBlogPage />} />
          <Route path="/secondBlog" element={<SecondBlogPage />} />
          <Route path="/thirdBlog" element={<ThirdBlogPage />} />
          <Route path="/fourthBlog" element={<FourthBlogPage />} />
          <Route path="/fifthBlog" element={<FifthBlogPage />} />
          <Route path="/sixthBlog" element={<SixthBlogPage />} />
          <Route path="/seventhBlog" element={<SeventhBlogPage />} />
          <Route path="/eighthBlog" element={<EighthBlogPage />} />
          <Route path="/ninethBlog" element={<NinethBlogPage />} />
          <Route path="/tenthBlog" element={<TenthBlogPage />} />
          <Route path="/branding" element={<BrandingSolutions />} />
          <Route path="/digital" element={<DigitalMarketing />} />
          <Route path="/custom" element={<CustomDevelopment />} />
          <Route path='/cyber' element={<CyberSecurity />} />
          <Route path='/customer' element={<CustomerRetension />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
        </Routes>
        <FooterContainer />
      </Router>
    </div>
  );
}
export default App;


// import React, { Suspense } from 'react';
// import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


// import NavbarContainer from './components/navbar/NavbarContainer';
// import FooterContainer from './components/footer/FooterContainer';

// //Lazy load components

// const LazyHomeContainer = React.lazy(() => import('./components/home/HomeContainer'));
// const LazyBlogContainer = React.lazy(() => import('./components/blog/BlogContainer'));
// const LazyServiceContainer = React.lazy(() => import('./components/services/ServiceContainer'));
// const LazyAboutUsContainer = React.lazy(() => import('./components/aboutUS/AboutUsContainer'));
// const LazyFirstBlogPage = React.lazy(() => import('./components/blog/FirstBlogPage'));
// const LazyDigitalMarketing = React.lazy(() => import('./components/services/digitalMarketing/DigitalMarketing'));
// const LazyCustomDevelopment = React.lazy(() => import('./components/services/customDevelopment/CustomDevelopment'));
// const LazyBrandingSolutions = React.lazy(() => import('./components/services/branding/BrandingSolutions'));
// const LazyContactUs = React.lazy(() => import('./components/contactUs/ContactUs'));
// const LazyCyberSecurity = React.lazy(() => import('./components/services/cyberSecurity/CyberSecurity'));
// const LazyCustomerRetension = React.lazy(() => import('./components/services/CustomerRetension/CustomerRetension'));
// const LazySecondBlogPage = React.lazy(() => import('./components/blog/SecondBlogPage'));
// const LazyThirdBlogPage = React.lazy(() => import('./components/blog/ThirdBlogPage'));
// const LazyFourthBlogPage = React.lazy(() => import('./components/blog/FourthBlogPage'));
// const LazyFifthBlogPage = React.lazy(() => import('./components/blog/FifthBlogPage'));
// const LazySixthBlogPage = React.lazy(() => import('./components/blog/SixthBlogPage'));
// const LazySeventhBlogPage = React.lazy(() => import('./components/blog/SeventhBlogPage'));
// const LazyEighthBlogPage = React.lazy(() => import('./components/blog/EighthBlogPage'));
// const LazyNinethBlogPage = React.lazy(() => import('./components/blog/NinethBlogPage'));
// const LazyTenthBlogPage = React.lazy(() => import('./components/blog/TenthBlogPage'));
// function App() {
//   return (
//     <div style={{ backgroundColor: '#000' }}>
//       <Router>
//         <NavbarContainer />
//         <Routes>
//           <Route path="/" element={< Suspense fallback={<div>Loading...</div>}><LazyHomeContainer /></Suspense>} />
//           <Route path="/services" element={< Suspense fallback={<div>Loading...</div>}><LazyServiceContainer /></Suspense>} />
//           <Route path="/aboutUs" element={< Suspense fallback={<div>Loading...</div>}><LazyAboutUsContainer /></Suspense>} />
//           <Route path="/blog" element={< Suspense fallback={<div>Loading...</div>}><LazyBlogContainer /></Suspense>} />
//           <Route path="/firstBlog" element={< Suspense fallback={<div>Loading...</div>}><LazyFirstBlogPage /></Suspense>} />
//           <Route path="/secondBlog" element={< Suspense fallback={<div>Loading...</div>}><LazySecondBlogPage /></Suspense>} />
//           <Route path="/thirdBlog" element={< Suspense fallback={<div>Loading...</div>}><LazyThirdBlogPage /></Suspense>} />
//           <Route path="/fourthBlog" element={< Suspense fallback={<div>Loading...</div>}><LazyFourthBlogPage /></Suspense>} />
//           <Route path="/fifthBlog" element={< Suspense fallback={<div>Loading...</div>}><LazyFifthBlogPage /></Suspense>} />
//           <Route path="/sixthBlog" element={< Suspense fallback={<div>Loading...</div>}><LazySixthBlogPage /></Suspense>} />
//           <Route path="/seventhBlog" element={< Suspense fallback={<div>Loading...</div>}><LazySeventhBlogPage /></Suspense>} />
//           <Route path="/eighthBlog" element={< Suspense fallback={<div>Loading...</div>}><LazyEighthBlogPage /></Suspense>} />
//           <Route path="/ninethBlog" element={< Suspense fallback={<div>Loading...</div>}><LazyNinethBlogPage /></Suspense>} />
//           <Route path="/tenthBlog" element={< Suspense fallback={<div>Loading...</div>}><LazyTenthBlogPage /></Suspense>} />
//           <Route path="/branding" element={< Suspense fallback={<div>Loading...</div>}><LazyBrandingSolutions /></Suspense>} />
//           <Route path="/digital" element={< Suspense fallback={<div>Loading...</div>}><LazyDigitalMarketing /></Suspense>} />
//           <Route path="/custom" element={< Suspense fallback={<div>Loading...</div>}><LazyCustomDevelopment /></Suspense>} />
//           <Route path='/cyber' element={< Suspense fallback={<div>Loading...</div>}><LazyCyberSecurity /></Suspense>} />
//           <Route path='/customer' element={< Suspense fallback={<div>Loading...</div>}><LazyCustomerRetension /></Suspense>} />
//           <Route path="/ContactUs" element={< Suspense fallback={<div>Loading...</div>}><LazyContactUs /></Suspense>} />
//         </Routes>
//         <FooterContainer />
//       </Router>
//     </div>
//   );
// }
// export default App;