const images = {
  logo_img: require("../assets/images/logo.svg"),
  close_icon: require("../assets/images/close.png"),
  menu_icon: require("../assets/images/menu.png"),
  about_us: require("../assets/images/aboutUs_logo.png"),
  youtube: require("../assets/images/youtube.png"),
  twitter: require("../assets/images/twitter.png"),
  linkedin: require("../assets/images/linkedin.png"),
  instagram: require("../assets/images/instagram.png"),
  client: require("../assets/images/client.png"),
  client1: require("../assets/images/client1.png"),
  client2: require("../assets/images/client2.png"),
  client3: require("../assets/images/client3.png"),
  client4: require("../assets/images/client4.png"),
  client5: require("../assets/images/client5.png"),
  client6: require("../assets/images/client6.png"),
  client7: require("../assets/images/client7.png"),
  client8: require("../assets/images/client8.png"),
  client9: require("../assets/images/client9.png"),
  client10: require("../assets/images/client10.png"),
  client11: require("../assets/images/client11.png"),
  client12: require("../assets/images/client12.png"),
  client13: require("../assets/images/client13.png"),
  client14: require("../assets/images/client14.png"),
  client15: require("../assets/images/client15.png"),
  client16: require("../assets/images/client16.png"),
  client17: require("../assets/images/client17.png"),
  client18: require("../assets/images/client18.png"),
  client19: require("../assets/images/client19.png"),
  client20: require("../assets/images/client20.png"),
  client21: require("../assets/images/client21.png"),
  client22: require("../assets/images/client22.png"),
  about_us_banner: require("../assets/images/about_us_banner.jpg"),
  about_us_circle: require("../assets/images/about_us_circle.png"),
  leadspotting: require("../assets/images/leadspotting.png"),
  teraffic: require("../assets/images/teraffic.png"),
  google: require("../assets/images/google.png"),
  amazon: require("../assets/images/amazon.png"),
  adobe: require("../assets/images/adobe.png"),
  Custom_bg: require("../assets/images/Custom_bg.jpg"),
  Custom_girl: require("../assets/images/Custom_girl.png"),
  Arjun: require("../assets/team/Arjunsir.png"),
  SanthoshKumar: require("../assets/team/Arjunsir.png"),
  Sridevi: require("../assets/team/Sri devi.png"),
  Ranjitha: require("../assets/team/Ranjitha.png"),
  Rakshith: require("../assets/team/Rakshith.png"),
  Shyam: require("../assets/team/Shyam.png"),
  Ashok: require("../assets/team/Ashok.png"),
  Tushara: require("../assets/team/Tushara.png"),
  Dayesh: require("../assets/team/Dayesh.png"),
  Raghavendra: require("../assets/team/Raghvendra.png"),
  arrow_circle: require("../assets/images/next_circle.png"),
  read_more_arrow: require("../assets/images/read_more_arrow.png"),
  Digital_bg: require("../assets/images/Digital_bg.png"),
  Digital_frame: require("../assets/images/Digital_frame.png"),
  Digital_man: require("../assets/images/Digital_man.png"),

  CyberSecurity: require("../assets/images/CyberSecurity.jpg"),
  brading_background_image: require("../assets/images/brading background image.jpg"),
  branding_man_image: require("../assets/images/branding man image.png"),
  round_spape_image: require("../assets/our_customer/round_spape_image.png"),
  outer_line_dashline: require("../assets/our_customer/outer_line_dashline.png"),
  info_logo: require("../assets/our_customer/info.png"),
  edulamp_logo: require("../assets/our_customer/edulamp.png"),
  ragini_logo: require("../assets/our_customer/ragini.png"),
  inner_line_image: require("../assets/our_customer/inner_line_image.png"),
  info_half_earth: require("../assets/our_customer/info_half_earth.png"),
  edulamp_half_earth: require("../assets/our_customer/edulamp_half_earth.png"),
  ragini_half_earth: require("../assets/our_customer/ragini_half_earth.png"),
  seo: require("../assets/images/seo.png"),
  left_arrow: require("../assets/images/left_arrow.png"),
  right_arrow: require("../assets/images/right_arrow.png"),
  //contact us
  contactus_bg: require("../assets/images/contactus_bg.jpg"),
  contactus_form_bg: require("../assets/images/contactus_form_bg.png"),
  mobileForm: require("../assets/images/mobileForm.png"),
  phone_call: require("../assets/images/phone-call.png"),
  email_symbol: require("../assets/images/email.png"),
  location_symbol: require("../assets/images/location.png"),
  x_symbol: require("../assets/images/x.png"),
  instagram_symbol: require("../assets/images/instagram.png"),
  facebook_symbol: require("../assets/images/facebook.png"),
  //service images
  Geek_Services_01: require("../assets/images/Geek-Services-01.jpg"),
  Geek_Services_02: require("../assets/images/Geek-Services-02.jpg"),
  Geek_Services_03: require("../assets/images/Geek-Services-03.jpg"),
  Geek_Services_04: require("../assets/images/Geek-Services-04.jpg"),
  Geek_Services_05: require("../assets/images/Geek-Services-05.jpg"),
  //Blogs image
  Ai_marketing: require("../assets/images/AI_Marketing.jpg"),
  Brand_storytelling: require("../assets/images/Brand_Storytelling.jpg"),
  Content_marketing: require("../assets/images/Content_Marketing.jpg"),
  Digital_advertising: require("../assets/images/Digital_Advertising_Strategies.jpg"),
  E_commerce: require("../assets/images/E_Commerce.jpg"),
  Importance_of_seo: require("../assets/images/Importance_of_SEO.jpg"),
  Responsive_webdesign: require("../assets/images/Responsive_Web_Design.jpg"),
  Social_media_marketing: require("../assets/images/Social_Media_Marketing.jpg"),
  Stunning_logos: require("../assets/images/Stunning_Logos.jpg"),
  Ux_design: require("../assets/images/UX_Design.jpg"),
  Coming_soon_banner: require("../assets/images/Coming soon banner.jpg"),
};


export default images;