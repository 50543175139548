import React, { useState, useRef } from "react";
import images from "../../../resources/images";
import "./BrandingSolutions.css";
import { NavLink } from "react-router-dom";
import videsss from '../../../assets/videos/Branding page V4.mp4';
import { Button, Container } from "react-bootstrap";

const BrandingSolutions = () => {
  const [click, setClick] = useState(false);
  const handleNavLinkClick = () => {
    if (click) {
      setClick(false);
    }

    window.scrollTo(0, 0);
  };

  const videoRef = useRef(null);

  const handleVideoEnded = () => {
    videoRef.current.play();
  };
  return (
    <div>
      <div className="main-image-container" >
        <img
          src={images.brading_background_image}
          alt="branding bg"
          className="main-image-style"
        />
        <div className="marquee-container">
          <div className="marquee">
            <span>Unforgettable Brands = Desirable + Distinct&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div className="marquee marquee2">
            <span>Unforgettable Brands = Desirable + Distinct&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
        </div>

        <div className="branding-man" >
          <img
            alt="Lightbulb"
            className="branding-man-style"
            src={images.branding_man_image}
          />
        </div>
      </div>

      <div className="container py-5">
        <div>
          <p className='missionText '>
            Immerse in timeless identity, sculpted by innovation, with Geekonomy's visionary branding solutions.
          </p>
          <p className='missionText '>
            Dive into the realm of unforgettable brand experiences with Geekonomy. Our visionary branding solutions transcend the ordinary, fusing cutting-edge design with immersive storytelling to craft identities that transcend time. Through captivating visuals and narratives that speak directly to the soul of your audience, we create brands that stand as beacons of innovation, leaving an indelible mark on the fabric of the future.
          </p>
        </div>
        <hr />

        <Container>
          <div className="py-5">
            <div className="container">
              <div className="video-containers">
                <video ref={videoRef} autoPlay loop muted onEnded={handleVideoEnded}>
                  <source src={videsss} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </Container>

        <div className="d-flex flex-column align-items-center  brandingSolutionFooter ">
          <p className="d-none d-md-block serviceFooter">
            We specialize in creating cohesive brand identities that resonate with your audience. From
            <div style={{ textAlign: "center" }}>
              {" "}
              logo design to brand strategy, we work closely with you to bring your vision to life.
            </div>
          </p>

          <p className="d-md-none serviceFooter rightContent">
            We specialize in creating cohesive brand identities that resonate with your audience. From
            logo design to brand strategy, we work closely with you to bring your vision to life.
          </p>
          <div className="mt-5">
            <Button className='aboutUsBtn' variant="light">
              <NavLink
                to="/ContactUs"
                onClick={() => { handleNavLinkClick(); }}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                Get In Touch
              </NavLink>
            </Button>
          </div>
        </div>
      </div>
    </div>

  );
};

export default BrandingSolutions;
